<template>
    <div>
        <template v-if="!isVertical">
            <div class="d-flex align-items-center member-change-dropdown-container">
                <!-- <button> -->
                    <!-- backToClientOverview -->
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Back"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <span id="target" role="button" class="material-icons-outlined custom-arrow-back-icon" @click="back()">
                            arrow_back_ios_new
                        </span>
                    </ejs-tooltip>
                <!-- </button> -->
                <b-dropdown id="dropdown-1" dropdown class="m-md-2" v-if="showMemberListDropdown">
                    <template #button-content>
                        <div class="dropdown-button-content">

                            <div class="member-avatar">
                                <img v-if="selectedClientProfilePhoto" :src="selectedClientProfilePhoto" width="45" alt="" style="border-radius: 50%"/>
                                <span v-else class="material-symbols-rounded border member-icon" style="font-size:2.2rem;color:white;padding:0.5rem;background-color: var(--base-theme-color);border-radius: 50%;"> person </span>
                                <!-- <img v-else src="@/assets/px/avatar-male.png" width="45" alt="" /> -->
                                
                               
                            </div>
                            <div class="name-internal-id ms-2">
                                <div class="member-name">
                                    <span>{{ `${slelectedLastName}, ${selectedFirstName}` }}</span>
                                    <!-- <span>{{ `${slelectedLastName}, ${selectedFirstName}` }}</span> -->
                                </div>
                                <div class="member-internal-id">
                                    <span>{{ `Internal ID# ${selectedClientId}` }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <input type="text" placeholder="Search.." id="myInput" @input="filterFunction" v-model="searchMember">
                    <button @click="clearSearch()" class="material-icons-outlined clear-member-dropdown-search" :class="searchMember.length === 0 ? 'd-none' : ''" title="Clear Search" >close</button>
                    <template v-if="clientsAssignedToProvider.length > 0">
                        <div class="members-list">
                            <b-dropdown-item v-for="(item, idx) in clientsAssignedToProvider" @click="itemClickHandler(item.id)" :key="idx"
                            :value="item.id">{{ `${item.last_name},
                                            ${item.first_name}` }}</b-dropdown-item>
                        </div>
                    </template>
                    <template v-if="clientsAssignedToProvider.length === 0">
                        <span class="text-no-member-found">No members found</span>
                    </template>
                </b-dropdown>
            </div>
        </template>
        
        <!-- ## Messaging screen member change dropdown ## -->
        <template v-if="isVertical">
            <div class="d-flex justify-content-center messaging-section-member-info">
                <div class="content-back-btn">
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Back"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <span id="target" role="button" class="material-symbols-outlined member-info-arrow-back" @click="back()">arrow_back_ios</span>
                    </ejs-tooltip>
                </div>
                <div class="d-flex flex-column align-items-center me-3">
                    <div class="member-info text-center mb-3">
                        <div class="member-info-avatar">
                            <img v-if="selectedClientProfilePhoto" :src="selectedClientProfilePhoto" width="90" alt="" style="border-radius: 50%"/>
                                <img v-else src="@/assets/px/avatar-male.png" width="90" alt="" />
                        </div>
                        <div class="member-info-text">
                            <div class="member-info-text-name">
                                <span>{{ `${slelectedLastName}, ${selectedFirstName}` }}</span>
                                <!-- <span>Smith, John</span> -->
                            </div>
                            <div class="member-info-text-internal-id">
                                <span>{{ `Internal ID# ${selectedClientId}` }}</span>
                                <!-- <span>Internal ID# 1048</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="member-change-dropdown-container">
                        <b-dropdown id="dropdown-1" dropdown class="m-md-2" v-if="showMemberListDropdown">
                            <template #button-content>
                                <span class="dropdown-placeholder">Select Other Member</span>
                            </template>
                            <input type="text" placeholder="Search.." id="myInput" @input="filterFunction" v-model="searchMember">
                            <button @click="clearSearch()" class="material-icons-outlined clear-member-dropdown-search" :class="searchMember.length === 0 ? 'd-none' : ''" title="Clear Search" >close</button>
                            <template v-if="clientsAssignedToProvider.length > 0">
                                <div class="members-list">
                                    <b-dropdown-item v-for="(item, idx) in clientsAssignedToProvider" @click="itemClickHandler(item.id)" :key="idx"
                                    :value="item.id">{{ `${item.last_name},
                                                    ${item.first_name}` }}</b-dropdown-item>
                                </div>
                            </template>
                            <template v-if="clientsAssignedToProvider.length === 0">
                                <span class="text-no-member-found">No members found</span>
                            </template>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { clients,file } from '@/util/apiRequests';
export default {
    name: 'MemberChangeDropdown',
    data() {
        return {
            searchMember: '',
            selectedClientProfilePhoto:null,
            selectedClientId: null,
            selectedFirstName: null,
            slelectedLastName: null,
            clientsAssignedToProvider: [],
            clientsAssignedToProviderOriginal: [],
        }
    },
    props: {
        page: {
            type: Object,
            required: true
        },
        currentClientId: {
            // type: String,
            required: true
        },
        // currentClientName: {
        //     required: true
        // },
        showMemberListDropdown: {
            type: Boolean,
            default: true
        },
        isVertical: {
            type: Boolean,
            default: false
        },
        showOnlyRelatedClients: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        itemClickHandler(value) {
            const selectedClient = this.clientsAssignedToProvider.find(client => client.id == value
            )
            this.$emit('action', {
                page: {
                    label: this.page.label,
                    name: this.page.name, "params": { "client_id": value }
                },
                selectedClient
            })
        },
        back() {
            // this.$router.go(-1, { client_id: this.$route.params.client_id });
            // this.$router.push({
            //     label: 'ClientOverview',
            //     name: 'ClientOverview', "params": { "record_id": this.$route.params.client_id }
            // });
            this.$emit('back');
        },
        async init() {
            try {
                const { client_id } = this.$route.params
                let { data } = await this.$api.get(clients.getV2ActiveClients())
                this.clientsAssignedToProvider = data
                this.clientsAssignedToProviderOriginal = data
                if (client_id) {
                    let foundClients = data.filter(client => client.id == client_id);
                    if (foundClients && foundClients.length > 0) {
                        this.selectedClientId = client_id
                        this.selectedFirstName = foundClients[0].first_name;
                        this.slelectedLastName = foundClients[0].last_name;
                        if(foundClients[0].client_profile_photo_file_id){
                        let result = await this.$api.get(file.getFile(foundClients[0].client_profile_photo_file_id));
                        const profileImage=result.data.file.Body
                        this.selectedClientProfilePhoto= profileImage ? `data:image/png;base64, ${profileImage}` : null;
                        }
                      
                    } else {
                        this.$toasted.error(`Member you are looking for doesn't exist`);
                    }
                    this.$emit('action', {
                    page: {
                        label: this.page.label,
                        name: this.page.name, "params": { "client_id": this.$route.params.client_id }
                    },
                    selectedClient: foundClients[0]
                })
                }
               
            }
            catch (e) {
                console.log(`Error getting active clients`, e);
                this.$toasted.error(`Error getting active clients`);
            }
        },
        clearSearch() {
            this.searchMember = '';
            this.filterFunction();
        },
        filterFunction() {
            let value = this.searchMember;
            if (value === '' || value === undefined)
                this.clientsAssignedToProvider = this.clientsAssignedToProviderOriginal
            const allClients = this.clientsAssignedToProviderOriginal
            const filteredClients = allClients.filter((client) => {
                const name = (client.last_name + `, ` + client.first_name).toLowerCase()
                if (name.includes(value.toLowerCase()))
                    return true
                return false
            })
            this.clientsAssignedToProvider = filteredClients
        }, reset(val) {
            console.log(val)
        },
    },
    watch: {
        "$route.params.client_id": {
            handler: async function (val) {
                this.selectedClientProfilePhoto=null
                if (this.clientsAssignedToProviderOriginal.length > 0) {
                    if (val) {
                        let foundClients = this.clientsAssignedToProviderOriginal.filter(client => client.id == val);
                        if (foundClients && foundClients.length > 0) {
                            this.selectedClientId = val;
                            this.selectedFirstName = foundClients[0].first_name;
                            this.slelectedLastName = foundClients[0].last_name;
                            if(foundClients[0].client_profile_photo_file_id){
                        let result = await this.$api.get(file.getFile(foundClients[0].client_profile_photo_file_id));
                        const profileImage=result.data.file.Body
                        this.selectedClientProfilePhoto= profileImage ? `data:image/png;base64, ${profileImage}` : 'no-image';
                        }
                        } else {
                            this.$toasted.error(`Member you are looking for doesn't exist`);
                        }
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    async created() {
        await this.init();
    }
}
</script>
<style scoped>
    .clear-member-dropdown-search {
        position: absolute;
        top: 8px;
        z-index: 4;
        right: 11px;
        font-size: 1.5em;
    }

    .text-no-member-found {
        display: inline-block;
        font: 600 13px "open sans";
        letter-spacing: 0.25px;
        border-radius: 5px;
        color: #A82A3D;
        background: white;
        position: relative;
        top: 3px;
        left: 3px;
    }
</style>